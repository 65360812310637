<template>
    <div>
        <top-nav></top-nav>
        <header-guide></header-guide>
        <main
            class="login-main"
            :style="{backgroundImage:'url('+$img.login_bg+')'}"
        >
            <div class="login-success">
                <div class="success-cont">
                    <h4 class="title">
                        <strong>{{userInfo.info.name}}</strong>您好，您已经成功登录询龙网
                    </h4>
                    <div class="suc-tips">
                        <em>账户类型：</em>
                        <span>{{userInfo.info.user_type==1?'工程极客':'雇主'}}</span>
                    </div>
                    <div
                        class="success-banner"
                        :style="{backgroundImage:'url('+$img.login_banner+')'}"
                        v-if="userInfo.info.attestation !=1"
                    >
                        <a
                            href="javascript:void(0)"
                            class="attest-btn"
                            @click="go_to('userAttest')"
                        >{{userInfo.info.user_type==1?'立即入驻':'立即认证'}}</a>
                    </div>
                    <div class="suc-btn-wrap">
                        <a
                            href="javascript:void(0)"
                            @click="go_to('userIndex')"
                        >前往我的工作台</a>
                        <a
                            href="javascript:void(0)"
                            @click="go_to('index')"
                        >前往首页</a>
                    </div>
                </div>
            </div>
        </main>
        <global-footer></global-footer>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
export default {
    name: "login",
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        GlobalFooter //公共底部
    },
    data() {
        return {
        };
    },
    created() {
        this.ajaxUserInfo();
    },
    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]),
        go_to(name) {
            this.$router.push({ name: name });
        }
    },
    computed: {
        ...mapState(["userInfo"])
    }
};
</script>


<style lang="less">
@import "../../styles/login.less";
</style>